import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import UserCard from 'components/UserCard'
import dayjs from 'dayjs'
import {
  useGetSubscriptionByOrderIdQuery,
  Wui_Order,
  Wui_OrderStatus_Enum,
} from 'generated/graphql'
import flow from 'lodash/fp/flow'
import kebabCase from 'lodash/fp/kebabCase'
import words from 'lodash/fp/words'
import capitalize from 'lodash/fp/capitalize'
import join from 'lodash/fp/join'
import map from 'lodash/fp/map'
import patternMatching from 'utils/patternMatching'
import { startCase } from 'lodash'
import friendlyAmount from 'utils/friendlyAmount'

type Props = {
  order: Pick<
    Wui_Order,
    'id' | 'status' | 'description' | 'title' | 'price'
  > & {
    when: string
    metadata?: Record<string, any>
  }
  pkg: {
    description: string[]
  }
  profile?: {
    name: string
    username?: string
    picture?: string
  }
}

const OrderSummary = ({ order, pkg, profile }: Props) => {
  // TODO: Change to regular request and use TRPC for batching
  const [{ data, fetching, error }] = useGetSubscriptionByOrderIdQuery({
    variables: { id: order.id },
  })

  if (fetching) {
    return <Loading $type="dots" />
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  const additionalServices =
    typeof order.metadata === 'object' &&
    Array.isArray(order.metadata.selectedPackageFees)
      ? order.metadata.selectedPackageFees.filter(
          ({ fee_type, amount }) => !!fee_type && !!amount,
        )
      : []

  return (
    <div className="space-y-3">
      <div className="text-xs">
        <span
          className={patternMatching([
            [Wui_OrderStatus_Enum.Open, 'text-primary'],
            [Wui_OrderStatus_Enum.Completed, 'text-action-success'],
            [Wui_OrderStatus_Enum.InProgress, 'text-action-warning'],
            [Wui_OrderStatus_Enum.WaitingForContent, 'text-action-warning'],
            [Wui_OrderStatus_Enum.Delivered, 'text-action-success'],
            [Wui_OrderStatus_Enum.Rejected, 'text-action-fail'],
            [Wui_OrderStatus_Enum.Pending, 'text-action-pending'],
            [Wui_OrderStatus_Enum.Cancelled, 'text-action-fail'],
          ])(order.status)}
        >
          {flow(kebabCase, words, map(capitalize), join(' '))(order.status)}
        </span>

        {data?.WUI_OrderSubscription && data?.WUI_OrderSubscription.length > 0 && (
          <>
            <span className="text-gray-600 px-3">|</span>
            <span className="text-gray-600">
              {data.WUI_OrderSubscription[0] &&
                capitalize(data.WUI_OrderSubscription[0].recurring_period)}
            </span>
          </>
        )}
        <span className="text-gray-600 px-3">|</span>
        <span className="text-gray-600">{`Order #${order.id}`}</span>
        <span className="text-gray-600 px-3">|</span>
        <span className="text-gray-600">{order.when}</span>

        {data?.WUI_OrderSubscription && data?.WUI_OrderSubscription.length > 0 && (
          <>
            <span className="text-gray-600 px-3">|</span>
            <span className="text-gray-600">
              {`Auto Renewal: ${
                data.WUI_OrderSubscription[0]
                  ? dayjs(
                      data.WUI_OrderSubscription[0].next_billing_date,
                    ).calendar()
                  : 'N/A'
              }`}
            </span>
          </>
        )}
      </div>

      {/* User */}
      {profile && <UserCard {...profile} />}

      {/* Package Info */}
      <div className="flex">
        <div className="pr-6 flex-grow">
          <div className="font-bold text-xl">{order.title}</div>
          <p className="text-gray-600 py-3">{order.description}</p>
          <div className="text-xs text-gray-600 pt-1">Package includes:</div>
          <ul className="list-disc list-inside">
            {pkg.description.map((description, i) => (
              <li key={`${i}-${description}`}>{description}</li>
            ))}
          </ul>
          {additionalServices.length > 0 && (
            <>
              <div className="text-xs text-gray-600 pt-3">
                Additional Services:
              </div>
              <ul className="list-disc list-inside">
                {additionalServices.map(({ fee_type, amount }, i: number) => (
                  <li key={`order-${order.id}-additional-${i}`}>
                    {startCase(fee_type)}{' '}
                    <span className="pl-2 text-gray-800">
                      ($
                      {friendlyAmount(amount)})
                    </span>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>

        <div className="text-xl text-gray-600 flex-shrink-0">{`$${friendlyAmount(
          order.price,
        )}`}</div>
      </div>
    </div>
  )
}

export default OrderSummary
