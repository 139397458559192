import cc from 'classcat'
import { useEffect } from 'react'
// import clarity from 'utils/clarity'

export const sanitizeError = (errorMsg?: string) =>
  errorMsg?.replace('[GraphQL]', '').trim()

type Props = {
  children: string
  $type: 'normal' | 'bright'
}

const ErrorMessage = ({ children, $type }: Props) => {
  const message = sanitizeError(children)

  useEffect(() => {
    // clarity('set', 'error-message', message || $type)
    import('clarity-js').then(({ clarity }) => {
      clarity.set('error-message', message || $type)
    })
  }, [])

  return (
    <div
      className={cc([
        'py-2',
        {
          'text-action-fail': $type === 'normal',
          'bg-action-fail px-2 text-white my-4': $type === 'bright',
        },
      ])}
      data-testid="error-message"
    >
      {message}
    </div>
  )
}

ErrorMessage.defaultProps = {
  $type: 'normal',
}

export default ErrorMessage
