import IconStar from 'assets/icons/star.svg'
import IconFacebook from 'assets/icons/social-facebook.svg'
import IconInstagram from 'assets/icons/social-instagram.svg'
import IconTikTok from 'assets/icons/social-tiktok.svg'
import IconYoutube from 'assets/icons/social-youtube.svg'
import IconTwitter from 'assets/icons/social-twitter.svg'
import IconTwitch from 'assets/icons/social-twitch.svg'
import Button from 'components/Button'
import patternMatching from 'utils/patternMatching'
import { FC } from 'react'
import cc from 'classcat'
import ProfilePicture from 'components/ProfilePicture'
import { useFeatureTesting } from 'stores/featureTesting.store'
import friendlyAmount from 'utils/friendlyAmount'
import type { Profile } from 'clients/auth.client'

type Props = {
  profile: Profile

  onClick: () => void

  // Optional, if not present a request will be made
  review_avg?: number
  social_media?: string[]
  highlights?: Record<string, unknown>[]
  from_price?: number
}

const InfluencerCard = ({
  profile,
  onClick,
  review_avg,
  social_media,
  highlights,
  from_price,
}: Props) => {
  const reviewFeatureTesting = useFeatureTesting('review')

  return (
    <div className="h-full flex items-center">
      <div className="bg-white border border-gray-400 rounded-lg overflow-hidden w-full">
        <div className="px-3 pt-3">
          <div className="flex w-full">
            {/* Rating (Star) */}
            {reviewFeatureTesting.enabled && review_avg != null && (
              <div className="text-center flex flex-col items-center">
                <IconStar width="20" className="fill-primary" fill="white" />
                <p className="text-xs text-gray-500">({review_avg})</p>
              </div>
            )}

            {/* Avatar */}

            <button onClick={onClick} className="flex-grow text-center">
              <ProfilePicture
                userID={profile.id}
                image={profile.picture}
                size="md"
                $showDot={false}
              />
            </button>
          </div>{' '}
          <button className="w-full" onClick={onClick}>
            {/* Influencer Information */}
            <div className="flex items-center flex-col gap-2">
              <div className="overflow-ellipsis overflow-hidden whitespace-nowrap text-lg font-bold w-11/12 text-center">
                {profile.publicName}
              </div>
              {social_media && (
                <div className="flex gap-3 mb-4">
                  {social_media.map((source, index) => (
                    <SocialIcon type={source} key={[index, source].join('-')} />
                  ))}
                </div>
              )}
            </div>

            {/* Separator */}
            <hr className="border-gray-500" />

            {/* Stats */}
            {highlights && (
              <div className="grid grid-cols-2 my-3">
                {highlights.map(({ value, title }: any, index) => (
                  <div
                    key={[index, title].join('-')}
                    className={cc([
                      'text-center border-gray-400',
                      { 'border-r': index < highlights.length - 1 },
                    ])}
                  >
                    <p className="font-bold text-xs  text-primary leading-none md:text-base">
                      {value}
                    </p>
                    <p className="text-xs text-gray-500 ">{title}</p>
                  </div>
                ))}
              </div>
            )}
          </button>
        </div>

        <Button
          type="button"
          $fluid
          $size="sm"
          $rounded={false}
          onClick={() => {
            onClick()
          }}
        >
          {from_price ? `From $${friendlyAmount(from_price)}` : 'View Profile'}
        </Button>
      </div>
    </div>
  )
}

const SocialIcon = ({ type }: { type: string }) => {
  const Component = patternMatching<string, FC<any>>([
    ['instagram', IconInstagram],
    ['facebook', IconFacebook],
    ['tiktok', IconTikTok],
    ['youtube', IconYoutube],
    ['twitter', IconTwitter],
    ['twitch', IconTwitch],
  ])(type)

  if (!Component) {
    return null
  }

  return <Component className="fill-gray-600" width={14} height={14} />
}

export default InfluencerCard
