import friendlyAmount from 'utils/friendlyAmount'

type Props = {
  data: number | string
  name: string
  price?: number
}

const DataCard = ({ data, name, price }: Props) => {
  return (
    <div className="bg-white border border-gray-400 rounded-lg overflow-hidden w-full text-center">
      <div className="bg-gradient-to-r from-primary to-secondary h-1.5 sm:h-2" />
      <div className="py-4">
        <div className="text-4xl sm:text-5xl text-primary font-bold">
          {friendlyAmount(data)}
        </div>
        <div className="text-gray-600 text-xs sm:text-md my-1">{name}</div>
        {price !== undefined && (
          <div className="text-xs sm:text-md text-action-success">
            ${friendlyAmount(price)}
          </div>
        )}
      </div>
    </div>
  )
}

export default DataCard
