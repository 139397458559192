import { FC } from 'react'
import {
  AnyVariables,
  CombinedError,
  OperationContext,
  useQuery,
  type UseQueryArgs,
} from 'urql'
import { LoadingSpinner } from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import Button from 'components/Button'

type ErrorProps = {
  error: CombinedError | Error
  reexecuteQuery: (opts?: Partial<OperationContext> | undefined) => void
}
type SuccessProps<DataT = unknown> = {
  data: DataT
}

type Props<DataT, VariablesT> = UseQueryArgs<
  VariablesT & AnyVariables,
  DataT
> & {
  Loading: FC
  Error: FC<ErrorProps>
  Success: FC<SuccessProps<DataT>>
  mapData?: (data: DataT) => any
}

function SimpleQuery<DataT = unknown, VariablesT = unknown>({
  Loading,
  Error: ErrorComponent,
  Success,
  ...props
}: Props<DataT, VariablesT>) {
  const [{ fetching, error, data }, reexecuteQuery] = useQuery(props)

  if (fetching) {
    return <Loading />
  }

  if (error) {
    return <ErrorComponent error={error} reexecuteQuery={reexecuteQuery} />
  }

  if (!data) {
    return (
      <ErrorComponent
        error={new Error(GENERIC_ERROR_MESSAGE)}
        reexecuteQuery={reexecuteQuery}
      />
    )
  }

  return <Success data={data} />
}

SimpleQuery.defaultProps = {
  Loading: () => (
    <div className="text-center py-5">
      <LoadingSpinner $size="md" $color="primary" $type="dots" />
    </div>
  ),
  Error: ({ error, reexecuteQuery }: ErrorProps) => (
    <div className="text-center">
      <ErrorMessage>{error.message}</ErrorMessage>
      <Button onClick={() => reexecuteQuery({ requestPolicy: 'network-only' })}>
        Retry
      </Button>
    </div>
  ),
}

export default SimpleQuery
