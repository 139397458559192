import { Wui_User } from 'generated/graphql'

// TODO: can we use Profile type from auth client?
type OrderUser = Pick<
  Wui_User,
  | 'id'
  | 'first_name'
  | 'last_name'
  | 'username'
  | 'entity_name'
  | 'full_name'
  | 'public_name'
> & {
  picture?: string
  coverPicture?: string
}

const sellerOrBuyer = (
  buyer: OrderUser,
  seller: OrderUser,
  user_id: number,
): OrderUser => {
  if (buyer.id === user_id) return seller
  else return buyer
}

export default sellerOrBuyer
