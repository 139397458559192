import cc from 'classcat'
import { useRouter } from 'next/router'
import { useState, useEffect, useRef, type ReactElement } from 'react'

type ID = string

type Item = {
  id: ID
  text: string | React.FC | ReactElement
  Component: React.FC
}

type Props = {
  items: Item[]
  $style?: 'text-base' | 'even'
  $hideSingleTab?: boolean
  activeTab?: string
  className?: string
  rootClass?: string
  onChange?: (id: ID) => void
  $type?: 'normal' | 'folder'
  linkToHash: boolean
}

const Tabs = ({
  items,
  $style,
  $hideSingleTab,
  activeTab,
  className,
  rootClass,
  onChange,
  linkToHash,
  $type,
}: Props) => {
  const router = useRouter()
  const [current, setCurrent] = useState<ID>(() => {
    if (activeTab) return activeTab

    if (router.isReady && router.asPath) {
      const currentFromPath = router.asPath.split('#')[1]

      if (
        currentFromPath &&
        items.some((item) => item.id === currentFromPath)
      ) {
        return currentFromPath
      }
    }

    return items?.[0]?.id?.toString() || ''
  })
  const item = items.find((i) => i.id.toString() === current) || items[0]
  const hasRendered = useRef(false)

  useEffect(() => {
    if (hasRendered.current) {
      if (linkToHash) {
        window.location.hash = `#${current}`
      }

      onChange?.(current)
    }
  }, [current])

  useEffect(() => {
    if (router.isReady && router.asPath) {
      setCurrent(
        router.asPath.split('#')[1] || items?.[0]?.id?.toString() || '',
      )
    }
  }, [router.isReady, router.asPath])

  useEffect(() => {
    if (activeTab) {
      setCurrent(activeTab)
    }
  }, [activeTab])

  useEffect(() => {
    hasRendered.current = true
  }, [])

  return (
    <div className={rootClass}>
      {(!$hideSingleTab || items.length > 1) && (
        <ul
          className={cc([
            'flex bg-gray-100 gap-3',
            {
              'justify-between': $style === 'text-base',
            },
            className,
          ])}
        >
          {items.map(({ id, text }, index) => {
            const isActive = id.toString() === current
            const TextComponent = typeof text === 'function' ? text : null

            return $type === 'normal' ? (
              <li
                key={`tabs-items-${id}`}
                className={cc([
                  {
                    'w-full': $style === 'even',
                  },
                ])}
              >
                <button
                  className={cc([
                    'py-2 md:text-sm',
                    {
                      'font-medium border-b-2 border-primary md:font-bold white bg-white rounded-t-md shadow text-primary':
                        isActive,
                      'text-gray-700': !isActive,
                      'w-full': $style === 'even',
                    },
                  ])}
                  type="button"
                  onClick={() => {
                    setCurrent(id.toString())
                  }}
                >
                  {TextComponent ? <TextComponent /> : (text as any)}
                </button>
              </li>
            ) : (
              <li
                key={`tabs-items-${id}`}
                style={{
                  width: `${(0.7 / items.length) * 100}%`,
                }}
                className={index > 0 ? '-ml-5' : undefined}
              >
                <button
                  className={cc([
                    'p-2 md:text-sm w-full rounded-t-2xl  shadow-md h-7 sm:h-8 relative',
                    {
                      'font-medium md:font-bold bg-white rounded-t-3xl text-primary':
                        isActive,
                      'text-gray-700 bg-gray-200': !isActive,
                      'w-full': $style === 'even',
                    },
                  ])}
                  type="button"
                  style={{ zIndex: !isActive ? items.length - index : 6 }}
                  onClick={() => {
                    setCurrent(id.toString())
                  }}
                >
                  {TextComponent ? <TextComponent /> : (text as any)}
                </button>
              </li>
            )
          })}
        </ul>
      )}

      {items && item && <item.Component />}
    </div>
  )
}

Tabs.defaultProps = {
  linkToHash: true,
  $style: 'text-base',
  $type: 'normal',
}

export default Tabs
