import { ReactElement, useEffect, useState } from 'react'
import Button from 'components/Button'
import Title from 'components/Title'
import cc from 'classcat'
import IconClose from 'assets/icons/x.svg'

type Props = {
  title: string
  subtitle: string | ReactElement
  buttonText: string
  buttonLink: string
  className?: string
  showCloseButton?: boolean
  name?: string
}

function NoResultTile({
  title,
  subtitle,
  buttonLink,
  buttonText,
  className,
  showCloseButton,
  name,
}: Props) {
  const key = `hide-tile-${name}`
  const [hideTitle, setHideTile] = useState<string | null>(null)

  useEffect(() => {
    setHideTile(window.localStorage.getItem(key))
  }, [])

  if (hideTitle && showCloseButton) {
    return null
  }

  return (
    <div className="relative">
      {showCloseButton && name && (
        <button
          className="absolute top-0 right-0 flex justify-center items-center bg-white rounded-full w-8 h-8 fill-gray-600 m-1"
          onClick={() => {
            localStorage.setItem(key, 'hide')
            setHideTile('hide')
          }}
        >
          <IconClose className="w-4 h-4" />
        </button>
      )}
      <div className={cc(['py-10 text-center', className])}>
        <div className="">
          <Title title={title} subtitle={subtitle} />
        </div>
        <Button $fluid className="mt-3" href={buttonLink}>
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default NoResultTile
