import { useAuth } from 'clients/auth.client'
import Button from 'components/Button'

export const useMissingLegalInformation = () => {
  const { account, isAuthenticated } = useAuth()

  return isAuthenticated && account && !account.hasCompletedLegalInformation
}

const MissingLegalInformation = () => {
  const isMissingLegalInformation = useMissingLegalInformation()

  if (isMissingLegalInformation) {
    return (
      <div className="border bg-white rounded-lg text-center p-4 my-6 py-10">
        <div className="text-lg font-bold">Verify your identify</div>
        <div className="text-gray-600">
          Your packages will be public and visible but cannot be purchased until
          you verify your identity.
        </div>
        <div className="pt-4 relative">
          <Button $fluid href="/settings/legal-information">
            Complete Identity Verification
          </Button>

          <span className="flex w-6 h-6 absolute top-1 -right-2">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-action-warning opacity-75" />
            <span className="flex items-center justify-center relative inline-flex rounded-full w-6 h-6 bg-action-warning">
              <strong>!</strong>
            </span>
          </span>
        </div>
      </div>
    )
  }

  return null
}

export default MissingLegalInformation
