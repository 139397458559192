import { ReactElement } from 'react'

type Props = {
  message: string
  className?: string
  subMessage?: string | ReactElement
}

function NoResultMessage({ message, className, subMessage }: Props) {
  return (
    <div className={className}>
      <div className="text-center text-lg font-bold text-gray-700">
        {message}
      </div>
      <div>{subMessage}</div>
    </div>
  )
}

export default NoResultMessage
