import Media from 'react-media'

type Props = {
  children: (matches: {
    sm: boolean
    md: boolean
    lg: boolean
    xl: boolean
  }) => any
}

const Breakpoint = ({ children }: Props) => {
  return (
    <Media
      queries={{
        sm: '(max-width: 639px)',
        md: '(min-width: 640px) and (max-width: 767px)',
        lg: '(min-width: 768px) and (max-width: 1023px)',
        xl: '(min-width: 1024px)',
      }}
    >
      {children}
    </Media>
  )
}

export default Breakpoint
