import cc from 'classcat'
import { useAuth } from 'clients/auth.client'
import { HERO_CAROUSEL } from 'globalConstants'
import shuffle from 'lodash/shuffle'
import Image from 'next/legacy/image'
import { Carousel } from 'react-responsive-carousel'
import styles from './index.module.scss'
import { Trans, useTranslation } from 'next-i18next'

const DashboardHero = () => {
  const { isAuthenticated, account } = useAuth()
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.root} data-testid="component-DashboardHero">
        <Carousel
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          emulateTouch
          infiniteLoop
          renderIndicator={(_onClick, isSelected) => (
            <li
              className={cc([
                'hidden inline-block w-2 h-2 rounded-full m-1',
                {
                  'bg-gray-400': !isSelected,
                  'bg-gradient-to-r from-primary to-secondary': isSelected,
                },
              ])}
              role="button"
            />
          )}
        >
          {[shuffle(HERO_CAROUSEL)[0]!].map(({ mobile, desktop }, index) => (
            <div
              key={`about-carousel-${index}`}
              className="max-w-full bg-white"
            >
              {/* Image */}
              <div
                className={cc([
                  styles.imageMobile,
                  'block md:hidden overflow-hidden',
                ])}
              >
                <Image
                  src={mobile}
                  alt="Hero"
                  width="821"
                  height="561"
                  // width="1536"
                  // height="1356"
                />
              </div>
              <div
                className={cc([
                  styles.imageDesktop,
                  'hidden md:block overflow-hidden rounded-b-3xl',
                ])}
              >
                <Image src={desktop} alt="Hero" width="1625" height="677" />
              </div>

              {/* Text */}
              <div className="px-4 md:pr-0 md:absolute text-black md:text-left md:bottom-20 lg:bottom-40 lg:left-20 md:pl-5 md:pl-10 md:pb-10 w-full tracking-wider">
                {isAuthenticated && (
                  <div className="text-gray-800 pb-2 md:pb-3">
                    {t('home:hi')}{' '}
                    <strong className="text-secondary">
                      {account.firstName}
                    </strong>
                  </div>
                )}
                <div className="text-3xl md:text-4xl lg:text-5xl font-bold lg:mb-5">
                  <Trans i18nKey="home:welcome" />
                </div>
                <div className="my-2 px-4 md:px-0 md:text-sm xl:text-lg lg:text-base">
                  <Trans
                    i18nKey="home:welcomeSub"
                    components={{
                      space: <br className="hidden md:block" />,
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  )
}

export default DashboardHero
