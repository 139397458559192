import {
  ProfilePackagesDocument,
  GetSocialMediaDemographicsDocument,
  GetSocialMediaLinksDocument,
  useProfileSettingsQuery,
  PortfolioPicturesDocument,
} from 'generated/graphql'
import { useAuth } from 'clients/auth.client'
import SimpleQuery from 'components/SimpleQuery'
import NoResultTile from 'components/NoResultTile'
import patternMatching from 'utils/patternMatching'
import { LoadingSpinner } from 'components/Loading'
import { useTranslation } from 'next-i18next'

function ActionsList() {
  const { t } = useTranslation('home')
  const { account } = useAuth()
  const [{ data, fetching }] = useProfileSettingsQuery({
    variables: { id: account?.id },
  })

  if (fetching) {
    return (
      <div className="text-center py-5">
        <LoadingSpinner $size="md" $color="primary" $type="dots" />
      </div>
    )
  }

  if (!data) {
    return null
  }

  const profile = data?.WUI_User_by_pk

  return (
    <div>
      {[
        {
          key: 'profile',
          buttonLink: '/profile/edit',
        },
        {
          key: 'demographics',
          buttonLink: '/profile/demographics',
        },
        {
          key: 'links',
          buttonLink: '/profile/links',
        },

        {
          key: 'packages',
          buttonLink: '/profile/packages',
        },
      ].map(({ key, buttonLink }) => {
        let query
        let extraValidation = false
        switch (key) {
          case 'links':
            query = GetSocialMediaLinksDocument
            break
          case 'demographics':
            query = GetSocialMediaDemographicsDocument
            if (!account?.isSeller) return null
            break
          case 'packages':
            query = ProfilePackagesDocument
            if (!account?.isSeller) return null
            break
          case 'profile':
            query = PortfolioPicturesDocument
            extraValidation = !profile?.about_me
            break
          default:
            query = ''
            break
        }

        return (
          <div
            className="my-6"
            data-testid={`action-${key}`}
            key={`action-list-${key}`}
          >
            <div className="bg-white">
              <SimpleQuery
                query={query}
                variables={{ userID: account?.id }}
                Success={({ data }: any) => {
                  const array = patternMatching<string, any>([
                    ['profile', data.WUI_UserFilePortfolio],
                    ['links', data.SocialMediaLink],
                    ['demographics', data.SocialMediaDemographic],
                    ['packages', data.WUI_SellerPackage],
                  ])(key)

                  if (array.length === 0 || extraValidation) {
                    return (
                      <NoResultTile
                        title={t(`actionList_${key}_title`)}
                        subtitle={t<string>(`actionList_${key}_title`)}
                        buttonText={t(`actionList_${key}_title`)}
                        buttonLink={buttonLink}
                        className="rounded-lg px-4"
                        showCloseButton
                        name={key}
                      />
                    )
                  }
                  return null
                }}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ActionsList
