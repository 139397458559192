import Tabs from 'components/Tabs'
import OrderSummary from 'components/OrderSummary'
import Button from 'components/Button'
import NoResultMessage from 'components/NoResultMessage'
import Container from 'components/Container'
import { LoadingSpinner } from 'components/Loading'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import {
  useMostRecentOpenOrdersQuery,
  useMostRecentPendingOrdersQuery,
  Wui_Order,
} from 'generated/graphql'
import { UseQueryState } from 'urql'
import dayjs from 'dayjs'
import sellerOrBuyer from 'utils/sellerOrBuyer'
import { useAuth } from 'clients/auth.client'
import ErrorMessage from 'components/ErrorMessage'
import { Trans, useTranslation } from 'next-i18next'

const TabsOrders = () => {
  const { t } = useTranslation('home')

  return (
    <Tabs
      className="md:rounded-t-lg"
      items={[
        {
          id: 'open-orders',
          text: (
            <Trans
              t={t}
              i18nKey="orders_recent_openTab"
              components={{
                br: <br />,
              }}
            />
          ),
          Component: TabOpenOrders,
        },
        {
          id: 'pending-orders',
          text: (
            <Trans
              t={t}
              i18nKey="orders_recent_pendingTab"
              components={{
                br: <br />,
              }}
            />
          ),
          Component: TabPendingOrders,
        },
      ]}
      $style="even"
      linkToHash={false}
    />
  )
}

export default TabsOrders

type TabContentProps = {
  state: UseQueryState
}

const TabContent = ({ state }: TabContentProps) => {
  const { t } = useTranslation('home')
  const { fetching, error, data } = state
  const { account } = useAuth()

  if (fetching) {
    return (
      <Container topAndBottom className="text-center">
        <LoadingSpinner $color="primary" $size="md" $type="logo" />
      </Container>
    )
  }

  if (error || !data) {
    return (
      <Container topAndBottom className="text-center">
        <ErrorMessage>
          {error ? error.message : GENERIC_ERROR_MESSAGE}
        </ErrorMessage>
      </Container>
    )
  }

  if (data.WUI_Order.length === 0) {
    return (
      <div className="py-5 md:shadow-lg md:px-4 md:rounded-b-xl bg-white">
        <NoResultMessage
          className="h-96 min-h-full flex justify-center items-center"
          message={t('orders_recent_nothing')}
        />
      </div>
    )
  }

  return (
    <div className="py-5 px-4 md:shadow-lg md:px-4 rounded-b-xl bg-white">
      <ul>
        {data.WUI_Order.map(
          ({
            id,
            status,
            title,
            description,
            items,
            created_at,
            WUI_User_Buyer,
            WUI_User_Seller,
            price,
          }: Wui_Order) => {
            const orderUser = sellerOrBuyer(
              {
                ...WUI_User_Buyer,
                picture: WUI_User_Buyer.WUI_File_Profile_Picture?.url,
              },
              {
                ...WUI_User_Seller,
                picture: WUI_User_Seller.WUI_File_Profile_Picture?.url,
              },
              account?.id || 0,
            )

            return (
              <li key={`order-${id}`}>
                {/* Status */}
                <OrderSummary
                  order={{
                    id,
                    status,
                    title,
                    description,
                    price,
                    when: `Started: ${dayjs(created_at).calendar()}`,
                  }}
                  profile={{
                    name:
                      orderUser.entity_name ||
                      orderUser.full_name ||
                      orderUser.username ||
                      '',
                    username: orderUser.username || '',
                    picture: orderUser.picture,
                  }}
                  pkg={{
                    description: items.map((item: any) => item.comment),
                  }}
                />

                {/* CTAs */}
                <div className="pt-8">
                  <Button $type="secondary" $fluid href={`/order/${id}`}>
                    {t('orders_recent_button_viewDetails')}
                  </Button>

                  {data.WUI_Order.length > 1 && (
                    <Button className="mt-3" $type="link" $fluid href="/orders">
                      {t('orders_recent_button_viewAll')}
                    </Button>
                  )}
                </div>
              </li>
            )
          },
        )}
      </ul>
    </div>
  )
}

const TabOpenOrders = () => {
  const [ordersState] = useMostRecentOpenOrdersQuery()

  // TODO: we need to pass the type to 'state'

  return <TabContent state={ordersState} />
}

const TabPendingOrders = () => {
  const [ordersState] = useMostRecentPendingOrdersQuery()

  // TODO: we need to pass the type to 'state'

  return <TabContent state={ordersState} />
}
