const friendlyAmount = (value: string | number | undefined | null): string => {
  if (value == null) return '---'

  const n = typeof value === 'string' ? Number(value) : value

  if (isNaN(n)) return value.toString()

  return n.toLocaleString(undefined, {
    minimumFractionDigits: Number.isInteger(n) ? 0 : 2,
    maximumFractionDigits: Number.isInteger(n) ? 0 : 2,
  })
}

export default friendlyAmount
